<template>
    <div class="header">
        <div class="back-icon" @click="back">
            <img src="@/assets/images/other/m/back.png" />
        </div>
        <div class="page-title">{{ title.replace(/\<\w+ \/\>/g, "") }}</div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
    },
    data() {
        return {};
    },
    methods: {
        back() {
            this.$router.go(-1);
        },
    },
};
</script>
<style lang="less" scoped>
.header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
    padding: 0 30px;
    background-color: #012f6d;

    img {
        width: 22px;
    }

    .page-title {
        width: 70%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 36px;
        text-align: center;
    }
}
</style>
