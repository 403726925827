<template>
    <div class="top backInUp">
        <div class="back btn-hover" @click="back">
            <img src="@/assets/images/other/back.png" />
        </div>

        <div class="title">
            <transition name="el-fade-in" mode="out-in">
                <img :src="title" :key="title" />
            </transition>
        </div>
        <div class="search">
            <img
                class="search-icon"
                src="@/assets/images/other/search-icon.png"
            />
            <input placeholder="请输入关键词查找" @input="onInput" />
            <div class="search-box" v-show="list.length">
                <div
                    class="search-item"
                    @click="jumpUrl(item.Link)"
                    v-for="item in list"
                    :key="item.id"
                >
                    {{ item.CompanyName }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { jump, search } from "@/utils/utils.js";

export default {
    props: ["title", "onBack"],
    data() {
        return {
            list: [],
        };
    },
    computed: {},
    methods: {
        back() {
            if (this.onBack && typeof this.onBack === "function") {
                this.onBack();
            } else {
                this.$router.back();
            }
        },

        onInput(event) {
            let keyword = event.target.value;
            this.list = search(keyword);
        },

        jumpUrl(url) {
            jump(url);
        },
    },
};
</script>
<style lang="less" scoped>
.top {
    height: 71px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 99;

    .back {
        cursor: pointer;

        img {
            width: 40px;
        }
    }

    .title {
        position: relative;
        left: 50%;
        transform: translate3d(-50%, 0, 0);

        img {
            height: 71px;
        }
    }

    .search {
        position: relative;
        width: 250px;
        height: 40px;
        background-color: #012f6d;
        border-radius: 20px;
        padding: 0 21px;
        display: flex;
        align-items: center;
        margin-left: auto;

        &-icon {
            width: 16px;
        }

        input {
            width: 100%;
            padding: 0 10px;
            background-color: transparent;
            outline: none;
            border: none;
            color: #fff;
            font-size: 16px;

            &::placeholder {
                color: #5b8faa;
            }
        }

        &-box {
            position: absolute;
            width: 100%;
            max-height: 250px;
            padding: 0 20px;
            left: 0;
            top: 50px;
            background-color: #012f6d;
            border-radius: 10px;
            overflow: auto;

            .search-item {
                color: #fff;
                padding: 15px 0;
                font-size: 16px;
                cursor: pointer;
                border-bottom: 1px solid #fff;

                &:hover {
                    color: #46c0ff;
                }

                &:last-of-type {
                    border-bottom: none;
                }
            }

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background: #012f6d; // 滑块颜色
                border-radius: 5px; // 滑块圆角
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #0b4780; // 鼠标移入滑块变红
            }

            &::-webkit-scrollbar-track {
                border-radius: 10px; // 轨道圆角
                background-color: #fff // 轨道颜色 ;
            }
        }
    }
}
</style>
