<template>
    <div class="page">
        <m-header title="展厅入口" />
        <div class="search-group backInUp">
            <div class="search-icon">
                <img src="@/assets/images/other/m/search-icon.png" />
            </div>
            <input
                type="text"
                @input="onInput"
                placeholder="请输入关键词查找"
            />

            <div class="search-box" v-if="searchList.length">
                <div
                    class="search-item"
                    v-for="item in searchList"
                    :key="item.id"
                    @click="jumpUrl(item.Link)"
                >
                    {{ item.CompanyName }}
                </div>
            </div>
        </div>

        <div class="title-png backInUp">
            <img src="@/assets/images/other/m/title-1.png" />
        </div>

        <div class="cate-list">
            <div
                class="cate-item"
                @click="jumpThird(item.type)"
                v-for="item in cateList"
                :key="item.type"
                :class="['backInUp', 'animation-delay-' + item.type]"
            >
                <img
                    :src="
                        require(`@/assets/images/other/m/cate-${item.type}.png`)
                    "
                />
                <div class="cate-top">
                    <div class="cate-name" v-html="item.name"></div>
                    <div class="arrow-right">
                        <img src="@/assets/images/other/arrow-right.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mHeader from "@/components/Header.m.vue";
import { cateList } from ".";
import { search, jump } from "@/utils/utils.js";
export default {
    data() {
        return {
            cateList,
            searchList: [],
        };
    },
    computed: {},
    methods: {
        jumpThird(type) {
            this.$router.push({
                name: "Third",
                params: { type },
            });
        },
        onInput(event) {
            let keyword = event.target.value;
            this.searchList = search(keyword);
        },
        jumpUrl(url) {
            jump(url, "_self");
        },
    },
    components: {
        mHeader,
    },
};
</script>
<style lang="less" scoped>
@keyframes shine {
    100% {
        left: 125%;
    }
}

.page {
    width: 100%;
    height: 100%;
    font-size: 32px;
    background-image: url("~@/assets/images/other/m/bg.png");
    background-size: 100% 100%;
    overflow: hidden;
}

.search-group {
    position: relative;
    width: 690px;
    height: 80px;
    margin: 40px 30px 0 30px;
    background-color: #012f6d;
    display: flex;
    align-items: center;
    border-radius: 40px;
    padding: 0 40px;
    z-index: 999;

    .search-icon {
        width: 31px;
        height: 34px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    input {
        font-size: 28px;
        background-color: transparent;
        border: none;
        outline: none;
        color: #fff;
        padding: 0 21px;

        &::placeholder {
            color: #5b8faa;
        }
    }

    .search-box {
        position: absolute;
        left: 0;
        top: 90px;
        width: 100%;
        max-height: 400px;
        border-radius: 8px;
        background-color: #012f6d;
        padding: 0 30px;
        overflow: auto;

        .search-item {
            color: #fff;
            padding: 20px 0;
            font-size: 32px;
            cursor: pointer;
            border-bottom: 1px solid #fff;

            &:hover {
                color: #46c0ff;
            }

            &:last-of-type {
                border-bottom: none;
            }
        }
    }
}

.title-png {
    text-align: center;

    img {
        width: 172px;
        margin: 45px 0 43px;
    }
}

.cate-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 30px;

    .cate-item {
        position: relative;
        width: 210px;
        height: 400px;
        margin-right: 30px;
        margin-bottom: 30px;
        overflow: hidden;

        .cate-top {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 120px;
            padding-top: 33px;
            font-size: 26px;
            text-align: center;

            .arrow-right {
                width: 0;
                position: absolute;
                right: 0;
                transition: all 0.4s;
                z-index: 9;

                img {
                    width: 24px;
                    height: 15px;
                }
            }

            .cate-name {
                position: relative;
                line-height: 28px;
                color: #fff;
                margin-bottom: 10px;
                z-index: 9;
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #0061ad;
                z-index: 1;
                opacity: 0;
                transition: all 0.4s;
            }
        }

        &::before {
            position: absolute;
            top: 0;
            left: -200%;
            display: block;
            content: "";
            width: 50%;
            height: 100%;
            background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0) 0,
                rgba(255, 255, 255, 0.3) 100%
            );
            transform: skewX(-19deg);
        }

        &:nth-of-type(2) {
            .cate-top {
                padding-top: 12px;
            }
        }

        &:nth-of-type(3n) {
            margin-right: 0;
        }

        &:hover {
            &::before {
                animation: shine 1.5s;
                animation-delay: 0.2s;
            }
            .cate-top::after {
                opacity: 1;
            }

            .arrow-right {
                left: 0;
                width: 100%;
            }
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
