<template>
    <component :is="componentName"></component>
</template>
<script>
import PcIndex from "./index.pc.vue";
import MIndex from "./index.m.vue";
import { isMobile } from "@/utils/utils";
export default {
    data() {
        return {
            componentName: "",
        };
    },
    created() {
        window.addEventListener("resize", this.onReize);
        this.onReize();
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onReize);
    },
    methods: {
        onReize() {
            this.componentName = isMobile()
                ? window.orientation === 0
                    ? "MIndex"
                    : "PcIndex"
                : "PcIndex";
        },
    },
    components: {
        PcIndex,
        MIndex,
    },
};
</script>
<style lang="less" scoped></style>
