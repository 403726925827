<template>
    <div class="container">
        <div class="main" ref="main" :style="mainStyle">
            <Top :title="require('@/assets/images/other/title-1.png')" />
            <div class="cate-box">
                <div
                    class="cate-item backInUp"
                    :class="'animation-delay-' + item.type"
                    @click="jumpThird(item.type)"
                    v-for="item in cateList"
                    :key="item.type"
                >
                    <img
                        class="cate-img"
                        :src="
                            require(`@/assets/images/other/cate-${item.type}.png`)
                        "
                    />
                    <div class="cate-top">
                        <div class="cate-name" v-html="item.name"></div>
                        <div class="arrow-right">
                            <img src="@/assets/images/other/arrow-right.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Top from "@/components/Top.vue";
import { cateList } from ".";
export default {
    data() {
        return {
            mainStyle: {},
            cateList,
        };
    },
    mounted() {
        this.onResize();
        window.addEventListener("resize", this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    },
    methods: {
        onResize() {
            const mainRef = this.$refs.main;
            const windowHeight = window.innerHeight;
            const mainHeight = mainRef.clientHeight;
            const scale = windowHeight / (mainHeight + 49);
            this.mainStyle = {
                transform: `scale(${scale > 1 ? 1 : scale})`,
                "transform-origin": "top center",
            };
        },
        jumpThird(type) {
            this.$router.push({
                name: "Third",
                params: { type },
            });
        },
    },
    components: {
        Top,
    },
};
</script>
<style lang="less" scoped>
@keyframes shine {
    100% {
        left: 125%;
    }
}

.container {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/images/other/bg.png");
    background-size: 100% 100%;
    overflow: hidden;
}

.main {
    position: absolute;
    left: 300px;
    right: 300px;
    padding-top: 150px;

    @media screen and (max-width: 1440px) {
        padding-top: 100px;
    }

    .cate-box {
        margin-top: 87px;
        display: flex;

        .cate-item {
            position: relative;
            width: 210px;
            height: 600px;
            margin-right: 12px;
            user-select: none;
            overflow: hidden;
            cursor: pointer;

            &:nth-of-type(2) .cate-top {
                padding-top: 21px;
            }

            .cate-top {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 120px;
                padding-top: 41px;
                text-align: center;

                .cate-name {
                    position: relative;
                    z-index: 9;
                    color: #fff;
                    font-size: 18px;
                    line-height: 20px;
                }

                .arrow-right {
                    width: 0%;
                    // margin-top: 20px;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    transition: all 0.4s;
                    z-index: 9;

                    img {
                        width: 24px;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #0061ad;
                    z-index: 1;
                    opacity: 0;
                    transition: all 0.4s;
                }
            }

            &::before {
                position: absolute;
                top: 0;
                left: -100%;
                display: block;
                content: "";
                width: 50%;
                height: 100%;
                background: linear-gradient(
                    to right,
                    rgba(255, 255, 255, 0) 0,
                    rgba(255, 255, 255, 0.3) 100%
                );
                transform: skewX(-19deg);
            }

            &:hover {
                &::before {
                    animation: shine 1.5s;
                    animation-delay: 0.2s;
                }
                .cate-top::after {
                    opacity: 1;
                }

                .arrow-right {
                    left: 0;
                    width: 100%;
                }
            }

            .cate-img {
                width: 100%;
                height: 100%;
            }

            &:last-of-type {
                margin-right: 0;
            }

            &:nth-of-type(2n) {
                margin-top: 60px;
            }
        }
    }
}
</style>
