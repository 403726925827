export const cateList = [
    {
        type: 1,
        name: "海洋港口与航运",
    },
    {
        type: 2,
        name: "海洋油气与<br />矿产资源开发",
    },
    {
        type: 3,
        name: "海洋电子信息",
    },
    {
        type: 4,
        name: "海洋工程和环保",
    },
    {
        type: 5,
        name: "海洋生物与医药",
    },
    {
        type: 6,
        name: "海洋旅游与文化",
    },
];
